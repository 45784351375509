body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.R_Modal {
  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  overflow-y: hidden;
}

.R_Modal_Scrollable {
  position: absolute;
  left: 25vw;
  top: 5vh;
  flex: 1;
  width: 50vw;
  height: 90vh;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  outline: none;
  margin-top: 50px;
  margin: auto;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  overflow-y: auto;
  z-index: 1500;
  justify-content: center;
}

.R_Modal_Scrollable > .timeStampForm {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.container {
  display: flex;
  justify-content: space-between;
}

.container * {
  margin: unset;
  padding: unset;
}

* > .activeView {
 background-color: rgb(33, 37, 41);
}

.popup {
  position: absolute;
  overflow-y: hidden;
  z-index: 999;
  padding: 10px;
  border: 1px solid grey;
  box-shadow: 0 0 2px 2px grey;
  background-color: moccasin;
  opacity: 0.95;
  width: 400px;
  left: -200px;
}

.closeTimestampModal {
  background-color: transparent;
  border: none;
}

#shareModalTextField {
  margin-bottom: 5px;
}

.section-header > * {
  font-size: 30px;
  font-weight: bold;
}

.section-item > * {
  width: 100%;
  height: 100%;
  font-size: 20px;
  padding: 0.25em 0;
}

:has(> .sharedWithUser) {
  background-color: dodgerblue;
}

.selectedMedia {
  box-shadow: 0px 0px 25px rgba(0, 200, 0, 0.9);
}

.notSelectedMedia {
  filter: grayscale(50%);
}

.downloadApkText {
  font-size: 20px;
  padding: 15px;
}
.wrapSelectList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 15px;
  padding-top: 20px;
}

.timeStampForm > .ignoreOpenEvent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: white;
  color: dodgerblue;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  margin-right: -1px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
}

.switch-field {
  display: flex;
  overflow: hidden;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: dodgerblue;
  box-shadow: none;
  color: white;
}

.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
  margin-left: 5px;
}

.switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
  margin-right: 5px;
}

.form {
  max-width: 600px;
  display: flex;
  font-family: "Lucida Grande", Tahoma, Verdana, sans-serif;
  font-weight: normal;
  line-height: 1.625;
  padding: auto;
  align-items: center;
}

.thumbnailList {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.thumbnailContainer {
  width: fit-content;
  height: fit-content;
  position: relative;
}
.thumbnailContainer > img {
  height: 250px;
}

.mediaOptions {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20%;
  padding: auto;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(30, 143, 255, 0.5);
}

.eventOption:hover {
  cursor: pointer;
}

@media (hover: hover) {
  .thumbnailContainer > .mediaOptions {
    display: none;
  }

  .thumbnailContainer:hover > .mediaOptions {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20%;
    padding: auto;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba(30, 143, 255, 0.5);
  }

  .thumbnailContainer:hover > img {
    filter: grayscale(80%);
    cursor: pointer;
  }
}

.mediaOptions > * {
  cursor: pointer;
}

.eventSetting {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.eventSetting > label {
  display: flex;
  cursor: pointer;
  gap: 5px;
}

.downloadButtons {
  display: flex;
  gap: 1px;
}

.downloadButtons > button {
  border-radius: unset;
}

.downloadButtons > button:first-of-type {
  border-radius: 5px 0 0 5px;
}

.downloadButtons > button:last-of-type {
  border-radius: 0 5px 5px 0;
}

.active_c > .page-link {
  z-index: unset;
  color: white;
  background-color: rgb(13, 110, 253);
  border-color: rgb(13, 110, 253);
}

.card_header {
  padding: auto;
  display: flex;
  align-items: center;
}

/* .timestampButtons > * {
width: 100%;
background-color: red;} */
